define('test-client/pods/site/inquire/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    actions: {
      submit: function submit(name, organization, email, phone, description) {
        var _this = this;

        if (!name || !organization || !email || !phone || !description) {
          return this.controller.set('error', true);
        }

        this.get('ajax').post('/inquire', {
          data: JSON.stringify({
            data: {
              type: 'inquire',
              attributes: {
                name: name,
                organization: organization,
                email: email,
                phone: phone,
                description: description
              }
            }
          })
        }).then(function () {
          var c = _this.controller;
          c.set('success', true);
          c.set('error');
          c.set('name');
          c.set('organization');
          c.set('email');
          c.set('phone');
          c.set('description');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });
});