define('test-client/pods/components/iq-chart/component', ['exports', 'ember'], function (exports, _ember) {

  var c3 = window.c3;
  exports['default'] = _ember['default'].Component.extend({
    elementId: 'chart',
    didInsertElement: function didInsertElement() {
      var progression = this.get('iq');
      var data = [['IQ', 100], ['95% Upper', 129.4], ['95% Lower', 70.6]];
      // don't display time if (on early administrations) we didn't record Time
      if (progression && progression[0] && progression[0].time !== null) {
        data.push(['Time', 8]);
      }
      progression.forEach(function (x) {
        data[0].push(x.iq);
        data[1].push((Math.round((x.confidence + x.iq) * 10) / 10).toFixed(1));
        data[2].push((Math.round((x.confidence * -1 + x.iq) * 10) / 10).toFixed(1));
        // don't display time if (on early administrations) we didn't record Time
        if (data[3]) {
          data[3].push((Math.round(Math.log(x.time) * 1000) / 1000).toFixed(3));
        }
      });

      c3.generate({
        bindto: '#chart',
        data: {
          columns: data,
          types: {
            'Time': 'spline',
            'IQ': 'spline',
            '95% Upper': 'area-spline',
            '95% Lower': 'area-spline'
          },
          axes: {
            'Time': 'y2',
            'IQ': 'y',
            '95% Upper': 'y',
            '95% Lower': 'y'
          },
          colors: {
            'Time': 'rgb(44, 160, 44)',
            'IQ': 'rgb(31, 119, 180)',
            '95% Upper': 'rgb(255, 127, 14)',
            '95% Lower': 'rgb(255, 127, 14)'
          }
        },
        axis: {
          y2: {
            show: true,
            label: 'ln(milliseconds)',
            position: 'outer-middle'
          }
        },
        area: {
          zerobased: false
        },
        tooltip: {
          show: false
        },
        onrendered: this.adjustLineFill
      });
    },
    adjustLineFill: function adjustLineFill() {
      window.$('path.c3-area-95--Lower', this.element).css({
        fill: 'rgb(255, 255, 255)',
        opacity: 1
      });
      var iq = window.$('g.c3-legend-item-IQ', this.element);
      window.$('.c3-legend-item-event', iq).remove();

      var confidence = window.$('g.c3-legend-item-95--Upper', this.element);
      window.$('.c3-legend-item-event', confidence).remove();
      window.$('text', confidence).text('Confidence');

      var time = window.$('g.c3-legend-item-Time', this.element);
      window.$('.c3-legend-item-event', time).remove();
    }
  });
});