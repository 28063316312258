define('test-client/pods/site/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      this.session.set('scheme', 'token');
      if (!window.$('link#site').length) {
        window.$('link#test-client').after('<link id="site" rel="stylesheet" href="site/assets/css/main.css">');
      }
    },
    model: function model(params, transition) {
      var _this = this;

      var id = this.storage.get('userID');
      if (id) {
        return this.store.find('identity', id).then(function (user) {
          return user;
        })['catch'](function () {
          _this.storage.set('userID');
          _this.storage.set('token');
          _this.controllerFor('site.signin').set('nextTransition', transition);
          _this.transitionTo('site.signin');
        });
      }
    },
    afterModel: function afterModel(model) {
      if (model) {
        this.storage.set('user', model);
      }
    },
    exit: function exit() {
      window.$('link#site').remove();
    }
  });
});