define('test-client/pods/components/example-slider/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    titleIndex: 1,
    classNames: ['instructions'],
    setSlide: (function () {
      this.set('slide', this.get('slides.firstObject'));
    }).on('didInsertElement'),
    change: function change(slides, index) {
      var _this = this;

      this.set('isHidden', true);
      var slide = this.get('slides').findBy('id', slides[index]);
      this.set('slide', slide);
      // this is a workaround for a Firefox ~45 bug that caused
      // the animation to stall after the first two images
      _ember['default'].run.next(function () {
        _this.set('isHidden');
      });
    },
    actions: {
      nextSlide: function nextSlide(current) {
        var slides = this.get('slides').map(function (x) {
          return x.id;
        }),
            index = slides.indexOf(current.id);
        if (slides[index + 1]) {
          this.incrementProperty('titleIndex');
          this.change(slides, index + 1);
        } else {
          this.set('viewedAll', true);
          this.set('titleIndex', 1);
          this.change(slides, 0);
        }
      }
    }
  });
});