define('test-client/helpers/short', ['exports', 'ember'], function (exports, _ember) {
  exports.short = short;

  function short(params /*, hash*/) {
    if (params && params[0] && params[0].length > 6) {
      var text = params[0].toString();
      return text.slice(0, 6) + '...';
    }
    return params;
  }

  exports['default'] = _ember['default'].Helper.helper(short);
});