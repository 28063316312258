define('test-client/pods/site/signout/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      Raven.setUserContext();
      this.storage.set('token');
      this.storage.set('userID');
      this.storage.set('legal');
      window.location = '/';
    }
  });
});