define('test-client/pods/site/reset/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    model: function model(params) {
      return params.token;
    },
    actions: {
      submit: function submit(token, password, password2) {
        var _this = this;

        if (!password || password !== password2) {
          return this.controller.set('error', true);
        }

        this.get('ajax').post('/reset-password', {
          data: JSON.stringify({
            data: {
              type: 'auth',
              attributes: {
                token: token,
                password: password
              }
            }
          })
        }).then(function () {
          _this.controller.set('error');
          _this.transitionTo('site.signin');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });
});