define('test-client/pods/site/signin/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    storage: _ember['default'].inject.service(),
    actions: {
      submit: function submit(email, password, resetPassword) {
        var _this = this;

        if (!email || !password && !resetPassword) {
          return this.controller.set('error', true);
        }

        var action = resetPassword ? '/reset-password-link' : '/token';
        this.get('ajax').post(action, {
          data: JSON.stringify({
            data: {
              type: 'auth',
              attributes: {
                username: email,
                password: password
              }
            }
          })
        }).then(function (result) {
          if (resetPassword) {
            _this.controller.set('email');
            _this.controller.set('error');
            return _this.controller.set('resetMessage', true);
          }
          _this.controller.set('error');
          _this.controller.set('resetMessage');
          _this.set('storage.token', result.data.token);
          _this.set('storage.userID', result.data.user);
          _this.transitionTo('site.account.legal');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });
});