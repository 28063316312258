define('test-client/pods/site/account/project/participants/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      state: {
        refreshModel: true
      }
    },
    model: function model(queryParams) {
      var params = this.paramsFor('site.account.project'),
          query = { project: params.id, search: queryParams.q };

      query = this.addStateSelection(query, queryParams);

      return _ember['default'].RSVP.hash({
        project: this.store.peekRecord('project', params.id),
        participants: this.store.query('participant', query),
        user: this.storage.get('user')
      });
    },
    throttleSearch: function throttleSearch(val) {
      this.replaceWith('site.account.project.participants', { queryParams: { q: val } });
    },
    actions: {
      search: function search(val) {
        _ember['default'].run.debounce(this, this.throttleSearch, val, 500);
      },
      loadMore: function loadMore(page) {
        var _this = this;

        var params = this.paramsFor('site.account.project'),
            queryParams = this.paramsFor('site.account.project.participants.index'),
            query = { project: params.id, search: queryParams.q, page: page };

        query = this.addStateSelection(query, queryParams);

        this.store.query('participant', query).then(function (data) {
          var participants = _this.get('controller.model.participants');
          participants.set('meta', data.meta);
          data.get('content').forEach(function (item) {
            participants.pushObject(item);
          });
        });
      },
      // this is a workaround for this bug: https://github.com/emberjs/ember.js/issues/5566
      // which causes errors to be logged even when query param transitions are happening properly
      queryParamsDidChange: function queryParamsDidChange() {
        _ember['default'].run.once(this, this.refresh);
      }
    },
    addStateSelection: function addStateSelection(query, queryParams) {
      if (queryParams.state === 'completed') {
        query.complete = 'True';
      } else if (queryParams.state === 'incomplete') {
        query.complete = 'False';
        query.started = 'True'; // can't do False as it's an isnull match on date
      } else if (queryParams.state === 'pending') {
          query.pending = 'True';
        }

      return query;
    }
  });
});