define('test-client/pods/site/account/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    storage: _ember['default'].inject.service(),
    beforeModel: function beforeModel(transition) {
      if (!this.get('storage.token') && transition.targetName !== 'site.signin') {
        this.controllerFor('site.signin').set('nextTransition', transition);
        return this.transitionTo('site.signin');
      }
      if (!this.get('storage.legal') && transition.targetName !== 'site.account.nda') {
        return this.transitionTo('site.account.legal');
      }
    },
    model: function model(params, transition) {
      this.session.set('scheme', 'token');
      var id = this.get('storage.userID');
      if (!id) {
        this.controllerFor('site.signin').set('nextTransition', transition);
        return this.transitionTo('site.signin');
      }
      return this.store.find('identity', id);
    },
    afterModel: function afterModel(model) {
      // this is important to make sure the localStorage version of user is up-to-date after sign-in
      this.set('storage.user', model);

      Raven.setUserContext({
        id: model.id,
        name: model.get('firstName') + ' ' + model.getWithDefault('lastName', '').slice(0, 1).trim()
      });

      if (!model.get('ndaAccepted')) {
        this.transitionTo('site.account.nda');
      }
    },
    actions: {
      transitionTo: function transitionTo(route) {
        this.transitionTo(route);
      },
      signDownload: function signDownload(project) {
        this.get('ajax').post('/projects/' + project.id + '/sign-export').then(function (result) {
          window.location.href = '' + result.data;
        })['catch'](function () {
          window.location.href = window.location.href;
        });
      }
    }
  });
});