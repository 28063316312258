define('test-client/locales/fr-ca/translations', ['exports'], function (exports) {
  exports['default'] = {
    'form.choice.info.education0': 'préscolaire (3 à 5 ans)',
    'form.choice.info.education1': 'maternelle (5 à 6 ans)',
    'form.choice.info.education2': '1ère année école primaire (6-7)',
    'form.choice.info.education3': '2ème année école primaire (7-8)',
    'form.choice.info.education4': '3ème année école primaire (8-9)',
    'form.choice.info.education5': '4ème année école primaire (9-10)',
    'form.choice.info.education6': '5ème année école primaire (10-11)',
    'form.choice.info.education7': '6ème année, école primaire (11-12)',
    'form.choice.info.education8': 'école secondaire 1 (12-13)',
    'form.choice.info.education9': 'école secondaire 2 (13-14)',
    'form.choice.info.education10': 'école secondaire 3 (14-15)',
    'form.choice.info.education11': 'école secondaire 4 (15-16)',
    'form.choice.info.education12': 'école secondaire 5 (16-17)',
    'form.choice.info.education13': '1ère année college',
    'form.choice.info.education14': 'Ddiplôme d’études professionnelles',
    'form.choice.info.education15': 'Etudes universitaires partielles',
    'form.choice.info.education16': 'Diplôme d\'études collégiales',
    'form.choice.info.education17': 'Bachelor',
    'form.choice.info.education18': 'Master',
    'form.choice.info.education19': 'Diplôme d’études supérieurs spécialisées',
    'form.choice.info.education20': 'Doctorat'
  };
});