define('test-client/pods/testing/puzzles/route', ['exports', 'ember', 'test-client/utils/constants'], function (exports, _ember, _testClientUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    // activate isn't called when entering via url
    beforeModel: function beforeModel(transition) {
      transition.send('testingStarted');
      window.$(window).bind('beforeunload', this.saveStatus.bind(this));
      window.$(window).bind('unload', this.saveStatus.bind(this));
    },
    saveStatus: function saveStatus() {
      var _this = this;

      // we bind to both beforeunload and unload for browser support
      if (this.saveStatusCompleted) {
        return;
      }

      var id = this.modelFor('testing').id;
      this.get('ajax').put('/participants/' + id + '/finish', {
        async: false,
        headers: {
          'X-Participant': id
        },
        data: {
          data: {
            id: id,
            type: 'participant',
            attributes: {
              status: _testClientUtilsConstants.STOP_BROWSER_CLOSED
            }
          }
        }
      }).then(function () {
        _this.saveStatusCompleted = true;
      });
    },
    model: function model() {
      return this.modelFor('testing').get('project.entryItem');
    },
    afterModel: function afterModel() {
      var participant = this.modelFor('testing'),
          id = participant.id;
      if (!participant.get('startedAt')) {
        this.get('ajax').put('/participants/' + id + '/start', {
          headers: {
            'X-Participant': id
          } });
      }

      // attempt to record the language used for tests
      var locale = this.get('i18n.locale');
      if (locale !== 'en') {
        participant.set('language', locale);
        participant.save();
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.set('participant', this.modelFor('testing'));
    },
    exit: function exit(transition) {
      if (transition && transition.targetName !== 'testing.complete' && transition.targetName !== 'testing.lifestyle') {
        transition.abort();
        // this keeps the url from getting out of sync (cosmetic only)
        if (window.history) {
          window.history.forward();
        }
      }
    },
    actions: {
      lifestyle: function lifestyle(status) {
        if (typeof status !== 'undefined') {
          var participant = this.modelFor('testing');
          participant.set('status', status);
        }
        this.set('session.completionCode', new Date().getTime().toString(16));
        this.transitionTo('testing.lifestyle');
      },
      complete: function complete(status) {
        if (typeof status !== 'undefined') {
          var participant = this.modelFor('testing');
          participant.set('status', status);
        }
        this.transitionTo('testing.complete');
      },
      willTransition: function willTransition() {
        window.$(window).unbind('unload');
        window.$(window).unbind('beforeunload');
        this.send('testingFinished');
      }
    }
  });
});