define('test-client/models/identity', ['exports', 'ember-data'], function (exports, _emberData) {

  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    email: attr(),
    firstName: attr(),
    lastName: attr(),
    avatar: attr(),
    plan: attr(),
    isApiManager: attr(),
    hidePendingParticipants: attr(),
    ndaAccepted: attr(),
    projects: _emberData['default'].hasMany('project', { async: true }),
    training: _emberData['default'].hasMany('training', { async: false })
  });
});