define('test-client/initializers/storage-service', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {
    var application = arguments[1] || arguments[0];
    application.inject('route', 'storage', 'service:storage');
    application.inject('component', 'storage', 'service:storage');
  }

  exports['default'] = {
    name: 'storage-service',
    initialize: initialize
  };
});