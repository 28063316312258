define('test-client/pods/testing/reminders/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('testing').get('project');
    },
    actions: {
      startTest: function startTest() {
        this.transitionTo('testing.puzzles');
      }
    }
  });
});