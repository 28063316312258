define('test-client/locales/it/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, _ember, _testClientLocalesEnTranslations) {
  exports['default'] = _ember['default'].merge(_ember['default'].copy(_testClientLocalesEnTranslations['default']), {
    'button.start': 'Sono pronto ad iniziare',
    'button.startTest': 'Inizia i Puzzles',
    'button.instructions': 'Start Istruzioni',
    'button.understand': 'Capito',
    'button.continue': 'Continua',
    'button.finished': 'Finito',

    'alert.fullscreen': 'Il tuo browser passerà in modalità schermo intero per ridurre al minimo le distrazioni.',
    'alert.requiredFields': 'Entrambi i campi Genere ed Età sono obbligatori.',
    'alert.invalidAge': 'L\'età (in anni) deve essere un numero superiore o uguale a 3.',
    'alert.selectEffort': 'Trascina il cursone per indicare quanto duramente hai tentato.',

    'title.welcome': 'Benvenuto',
    'title.demographics': 'Iniziamo con qualcosa su di te.',
    'title.instructions': 'Esempio 1',
    'title.reminders': 'Iniziamo!',
    'title.feedback': 'Quanto a lungo hai provato?',
    'title.lifestyle': 'Bel lavoro con questi puzzles!',
    'title.complete': 'Grazie per aver partecipato!',

    'form.field.required': 'richiesto',

    'form.label.gender': 'Genere',
    'form.label.age': 'Età',
    'form.label.race': 'Etnia',
    'form.label.education': 'Educazione',
    'form.label.didNotTry': 'Non ho provato',
    'form.label.didMyBest': 'Ho fatto del mio meglio',
    'form.label.set': 'Partita',
    'form.label.score': 'Punteggio',
    'form.label.lifestyle.motivation': 'Nella vita considero solo me stesso:',
    'form.label.lifestyle.school': 'In generale mi piace la scuola:',
    'form.label.lifestyle.rest': 'Mi sento riposato dopo aver dormito:',
    'form.label.lifestyle.restMinor': 'Durante il giorno mi sento stanco:',
    'form.label.lifestyle.fitness': 'Fisicamente sono:',
    'form.label.lifestyle.handedness': 'Uso principalmente la mia mano:',
    'form.label.lifestyle.games': 'Al computer gioco a:',
    'form.label.lifestyle.smoking': 'io sono un',
    'form.label.lifestyle.alcohol': 'Io bevo alcool',
    'form.label.lifestyle.caffeine': 'I bevo caffè/tè:',
    'form.label.lifestyle.control': 'Nella vita ho la sensazione di avere:',

    'form.choice.info.genderA': 'Maschio',
    'form.choice.info.genderB': 'Femmina',
    'form.choice.info.race0': 'Indiana Americana',
    'form.choice.info.race1': 'Asiatica',
    'form.choice.info.race2': 'Mista',
    'form.choice.info.race3': 'Africana',
    'form.choice.info.race4': 'Caucasica',
    'form.choice.info.race5': 'Altro',
    'form.choice.info.education0': 'Prescolare',
    'form.choice.info.education1': 'Scuola Elementare',
    'form.choice.info.education2': 'Prima Media',
    'form.choice.info.education3': 'SecondaMedia',
    'form.choice.info.education4': 'Terza Media',
    'form.choice.info.education5': 'Prima Superiore',
    'form.choice.info.education6': 'Seconda Superiore',
    'form.choice.info.education7': 'Terza Superiore',
    'form.choice.info.education8': 'Quarta Superiore',
    'form.choice.info.education9': 'Quinta Superiore',
    'form.choice.info.education10': '9 ° grado',
    'form.choice.info.education11': '10 ° grado',
    'form.choice.info.education12': '11 ° grado',
    'form.choice.info.education13': '12 ° grado',
    'form.choice.info.education14': 'Istituto Professionale',
    'form.choice.info.education15': 'Licenza Media',
    'form.choice.info.education16': 'Diploma Univeristario',
    'form.choice.info.education17': 'Laurea triennale',
    'form.choice.info.education18': 'Laurea Magistrale',
    'form.choice.info.education19': 'Scuola di Specializzazione/Master',
    'form.choice.info.education20': 'Dottorato',
    'form.choice.lifestyle.motivationA': 'Non molto motivato',
    'form.choice.lifestyle.motivationB': 'Abbastanza motivato',
    'form.choice.lifestyle.motivationC': 'Molto motivato',
    'form.choice.lifestyle.schoolA': 'Non tanto',
    'form.choice.lifestyle.schoolB': 'Un po\'',
    'form.choice.lifestyle.schoolC': 'Tantissimo',
    'form.choice.lifestyle.restA': 'Mai',
    'form.choice.lifestyle.restB': 'Qualche volta',
    'form.choice.lifestyle.restC': 'Sempre',
    'form.choice.lifestyle.smokingA': 'Non fumatore',
    'form.choice.lifestyle.smokingB': 'Fumatore occasionale',
    'form.choice.lifestyle.smokingC': 'Fumatore',
    'form.choice.lifestyle.alcoholA': 'Mai',
    'form.choice.lifestyle.alcoholB': '1-4 biccheri a settimana',
    'form.choice.lifestyle.alcoholC': '5 o più bicchieri a settimana',
    'form.choice.lifestyle.caffeineA': 'Mai',
    'form.choice.lifestyle.caffeineB': 'Una volta al giorno',
    'form.choice.lifestyle.caffeineC': 'Più di una volta al giorno',
    'form.choice.lifestyle.controlA': 'Molto poco controllo',
    'form.choice.lifestyle.controlB': 'Abbastanza controllo',
    'form.choice.lifestyle.controlC': 'Molto controllo',
    'form.choice.lifestyle.fitnessA': 'Sedentario (poca attività quotidiana)',
    'form.choice.lifestyle.fitnessB': 'Attivo(moderata attività quotidiana)',
    'form.choice.lifestyle.fitnessC': 'Atletico(attività fisica regolare)',
    'form.choice.lifestyle.handednessA': 'Destra',
    'form.choice.lifestyle.handednessB': 'Sinistra',
    'form.choice.lifestyle.handednessC': 'Entrambe le mani',
    'form.choice.lifestyle.gamesA': 'Mai',
    'form.choice.lifestyle.gamesB': 'Qualche volta',
    'form.choice.lifestyle.gamesC': 'Tutti i giorni',

    'copy.reminders.nextPage': 'Nella pagina successiva potrai iniziare i Puzzles.',
    'copy.reminders.doYourBest': 'Prova a fare del tuo meglio.',
    'copy.reminders.refresh': 'Non aggiornare la pagina.',
    'copy.reminders.distractions': 'ti pregherei di spegnere il cellulare e allontanare qualunque oggetto possa provocare distrazioni.',
    'copy.lifestyle.instructions': 'Per favore',
    'copy.complete.noURL': '',
    'copy.complete.URL': 'Sarai riportato presto indietro',
    'copy.complete.URLTime': 'in 10 secondi.'
  });
});