define('test-client/locales/zh/translations', ['exports'], function (exports) {
  exports['default'] = {
    'button.start': '准备完毕',
    'button.startTest': '开始实验',
    'button.instructions': '开始指示',
    'button.understand': '我懂',
    'button.continue': '继续',
    'button.finished': '完毕',

    'alert.fullscreen': '全屏开始',
    'alert.requiredFields': '必填性别与年龄',
    'alert.invalidAge': '年龄必须大于三岁',
    'alert.selectEffort': '请选难度指示',
    'alert.feedbackFormError': 'Sorry, there was an error. Make sure your internet is connected and then try again.',

    'title.welcome': '欢迎',
    'title.demographics': '先咨询一下您的情况',
    'title.instructions': '例子',
    'title.reminders': '马上开始',
    'title.feedback': '请选难度指示',
    'title.lifestyle': '实验加油',
    'title.complete': '多谢参加',

    'form.field.required': '必填',

    'form.label.gender': '性别',
    'form.label.age': '年龄',
    'form.label.race': '种族',
    'form.label.education': '教育程度',
    'form.label.didNotTry': '我没有花力气',
    'form.label.didMyBest': '我花了最大的力',
    'form.label.set': '安置',
    'form.label.score': '分数',
    'form.label.lifestyle.motivation': '我自认为',
    'form.label.lifestyle.school': '我喜欢上学',
    'form.label.lifestyle.rest': '我睡眠很不错',
    'form.label.lifestyle.restMinor': '我白天会犯困',
    'form.label.lifestyle.fitness': '我身理上',
    'form.label.lifestyle.handedness': '我用大部分我的',
    'form.label.lifestyle.games': '我打电脑游戏',
    'form.label.lifestyle.smoking': '我是一个',
    'form.label.lifestyle.alcohol': '我喝酒',
    'form.label.lifestyle.caffeine': '我喝咖啡／茶',
    'form.label.lifestyle.control': '我生活中感到',

    'form.choice.info.genderA': '男',
    'form.choice.info.genderB': '女',
    'form.choice.info.race0': '美印第安人',
    'form.choice.info.race1': '亚洲人',
    'form.choice.info.race2': '混血',
    'form.choice.info.race3': '黑人',
    'form.choice.info.race4': '白人',
    'form.choice.info.race5': '其他',
    'form.choice.info.education0': '幼儿园',
    'form.choice.info.education1': '学前班',
    'form.choice.info.education2': '一年级',
    'form.choice.info.education3': '二年级',
    'form.choice.info.education4': '三年级',
    'form.choice.info.education5': '四年级',
    'form.choice.info.education6': '五年级',
    'form.choice.info.education7': '六年级',
    'form.choice.info.education8': '七年级',
    'form.choice.info.education9': '八年级',
    'form.choice.info.education10': '九年级',
    'form.choice.info.education11': '十年级',
    'form.choice.info.education12': '十一年级',
    'form.choice.info.education13': '十二年级',
    'form.choice.info.education14': '中专',
    'form.choice.info.education15': '一些大学',
    'form.choice.info.education16': '大专',
    'form.choice.info.education17': '学士',
    'form.choice.info.education18': '硕士',
    'form.choice.info.education19': '专业学位',
    'form.choice.info.education20': '博士',
    'form.choice.lifestyle.motivationA': '没有动力',
    'form.choice.lifestyle.motivationB': '有一些动力',
    'form.choice.lifestyle.motivationC': '很有动力',
    'form.choice.lifestyle.schoolA': '没有',
    'form.choice.lifestyle.schoolB': '有一点',
    'form.choice.lifestyle.schoolC': '有很多',
    'form.choice.lifestyle.restA': '从没有',
    'form.choice.lifestyle.restB': '有时候有',
    'form.choice.lifestyle.restC': '常有',
    'form.choice.lifestyle.smokingA': '非烟民',
    'form.choice.lifestyle.smokingB': '前烟民',
    'form.choice.lifestyle.smokingC': '烟民',
    'form.choice.lifestyle.alcoholA': '从没有',
    'form.choice.lifestyle.alcoholB': '每周一到四杯',
    'form.choice.lifestyle.alcoholC': '每周五杯以上',
    'form.choice.lifestyle.caffeineA': '从没有',
    'form.choice.lifestyle.caffeineB': '每天一次',
    'form.choice.lifestyle.caffeineC': '每天两次以上',
    'form.choice.lifestyle.controlA': '没掌握',
    'form.choice.lifestyle.controlB': '有些掌握',
    'form.choice.lifestyle.controlC': '很有掌握',
    'form.choice.lifestyle.fitnessA': '活动很少',
    'form.choice.lifestyle.fitnessB': '活动正常',
    'form.choice.lifestyle.fitnessC': '活动很多',
    'form.choice.lifestyle.handednessA': '右手',
    'form.choice.lifestyle.handednessB': '左手',
    'form.choice.lifestyle.handednessC': '双手',
    'form.choice.lifestyle.gamesA': '从没有',
    'form.choice.lifestyle.gamesB': '有时候有',
    'form.choice.lifestyle.gamesC': '大多时候有',

    'copy.reminders.nextPage': '下页试验开始',
    'copy.reminders.doYourBest': '请尽力',
    'copy.reminders.refresh': '不要翻新',
    'copy.reminders.distractions': '开始前请关手机',
    'copy.lifestyle.instructions': '请结束试验',
    'copy.complete.noURL': '试验结束。 多谢参与。',
    'copy.complete.URL': '你会被带回',
    'copy.complete.URLTime': '十秒后'
  };
});