define('test-client/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax', 'test-client/config/environment'], function (exports, _ember, _emberAjaxServicesAjax, _testClientConfigEnvironment) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    host: _testClientConfigEnvironment['default'].host,
    namespace: _testClientConfigEnvironment['default'].namespace,
    storage: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    headers: _ember['default'].computed('storage.token', 'session.hash', 'session.scheme', {
      get: function get() {
        var headers = {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json'
        };

        if (this.get('session.scheme') === 'token' && this.get('storage.token')) {
          headers['Authorization'] = 'Token ' + this.get('storage.token');
        } else if (this.get('session.scheme') !== 'token') {
          headers['X-Participant'] = this.get('session.hash');
        }

        return headers;
      }
    })
  });
});