define('test-client/pods/site/account/nda/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('site.account');
    },
    afterModel: function afterModel() {
      Raven.captureMessage('NDA screen shown for user', {
        level: 'info'
      });
    },
    actions: {
      agree: function agree() {
        var _this = this;

        var model = this.modelFor('site.account.nda');
        model.set('ndaAccepted', true);
        model.save().then(function () {
          var next = _this.controllerFor('site.signin').get('nextTransition');
          if (next) {
            _this.controllerFor('site.signin').set('nextTransition');
            return next.retry();
          }
          _this.transitionTo('site.account');
        });
      }
    }
  });
});