define('test-client/pods/site/account/settings/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    storage: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      if (!this.storage.get('user.isApiManager')) {
        return this.transitionTo('site.account');
      }
    },
    model: function model() {
      return _ember['default'].RSVP.hash({
        keys: this.store.findAll('key')
      });
    }
  });
});