define('test-client/adapters/choice', ['exports', 'test-client/adapters/application'], function (exports, _testClientAdaptersApplication) {
  exports['default'] = _testClientAdaptersApplication['default'].extend({
    findMany: function findMany(store, type, ids, snapshots) {
      var url = this.buildURL(type.modelName, ids, snapshots, 'findMany');
      return this.ajax(url, 'GET', {
        data: {
          include: 'item,next',
          filter: {
            id: ids.join(',')
          }
        }
      });
    }
  });
});