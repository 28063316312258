define('test-client/pods/testing/complete/route', ['exports', 'ember', 'test-client/utils/fullscreen'], function (exports, _ember, _testClientUtilsFullscreen) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      var _this = this;

      var id = this.session.get('hash');
      // if the participant is loaded then the project will be too
      if (id && !this.store.peekRecord('participant', id)) {
        return this.get('ajax').request('/participants/' + id + '/project', {
          headers: {
            'X-Participant': id
          }
        }).then(function (data) {
          _this.store.push(data);
        })['catch'](function () {
          Raven.captureMessage('Access to project was denied. Is project active?', {
            level: 'info'
          });
        });
      }
    },
    model: function model() {
      var model = {
        project: this.store.peekAll('project').get('firstObject'),
        participant: this.modelFor('testing'),
        code: this.get('session.completionCode')
      };

      model.url = this.checkRedirect(this.modelFor('testing'), model.project, model.code);
      this.externalRedirect(model.url);

      return model;
    },

    afterModel: function afterModel() {
      (0, _testClientUtilsFullscreen.leaveFullscreen)();

      var id = this.session.get('hash'),
          participant = this.modelFor('testing');
      var status;

      if (participant) {
        status = participant.get('status');
      }

      this.get('ajax').put('/participants/' + id + '/finish', {
        headers: {
          'X-Participant': id
        },
        data: {
          data: {
            id: id,
            type: 'participant',
            attributes: {
              status: status
            }
          }
        }
      })['catch'](function () {
        // we don't record an error because most requests will fail (any time the administration was stopped server side).
      });
    },

    checkRedirect: function checkRedirect(participant, project, code) {
      if (project && participant) {
        var completeUrl = project.getWithDefault('completeUrl', ''),
            rejectUrl = project.getWithDefault('rejectUrl', '');

        var id = participant.getWithDefault('subjectIdentifier', '');

        if (code && completeUrl) {
          return completeUrl.replace('{0}', id);
        } else if (!code && rejectUrl) {
          return rejectUrl.replace('{0}', id);
        }
      }
    },

    externalRedirect: function externalRedirect(url) {
      if (url) {
        _ember['default'].run.later(function () {
          window.location.href = url;
        }, 10000);
      }
    }

  });
});