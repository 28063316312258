define('test-client/helpers/fixed', ['exports', 'ember'], function (exports, _ember) {
  exports.fixed = fixed;

  function fixed(params /*, hash*/) {
    if (!isNaN(parseFloat(params[0]))) {
      return params[0].toFixed(1);
    }
  }

  exports['default'] = _ember['default'].Helper.helper(fixed);
});