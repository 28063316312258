define('test-client/pods/testing/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      this.session.set('scheme');
      // get ride of the public site CSS
      window.$('link#site').remove();
    },
    beforeModel: function beforeModel(transition) {
      var id = transition.params.testing.id;
      if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id)) {
        this.transitionTo('/404/invalid-id');
      }
    },
    model: function model(params, transition) {
      this.session.set('hash', params.id);
      return this.store.findRecord('participant', params.id, {
        adapterOptions: {
          query: {
            include: 'project,project.entry_item,project.instructions,responses'
          }
        }
      }).then(function (participant) {
        return participant;
      })['catch'](function () {
        transition.router.transitionTo('testing.complete');
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      if (model && !model.get('project.enableLocalization')) {
        _ember['default'].run.later(function () {
          _this.replaceWith(transition.targetName, { queryParams: { lang: 'en' } });
        });
      }
    },
    actions: {
      testingStarted: function testingStarted() {
        var _this2 = this;

        _ember['default'].run.scheduleOnce('afterRender', function () {
          // set a flag for show/hide of localization
          _this2.controller.set('testing', true);
        });
      },
      testingFinished: function testingFinished() {
        this.controller.set('testing');
      }
    }
  });
});