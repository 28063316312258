define('test-client/adapters/identity', ['exports', 'test-client/adapters/application'], function (exports, _testClientAdaptersApplication) {
  exports['default'] = _testClientAdaptersApplication['default'].extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      return this.ajax(url, 'GET', {
        data: {
          include: 'projects,training'
        }
      });
    }
  });
});