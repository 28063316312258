define('test-client/pods/components/language-select/component', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;
  exports['default'] = Component.extend({
    tagName: 'ul',
    classNames: ['i18n', 'language-select'],
    i18n: inject.service(),
    current: (function () {
      return this.get('locales').findBy('id', this.get('i18n.locale'));
    }).property('i18n.locale'),

    locales: (function () {
      var i18n = this.get('i18n');
      return [{ id: 'zh', text: i18n.t('locale.zh'), flag: 'cn' }, { id: 'de', text: i18n.t('locale.de'), flag: 'de' }, { id: 'da', text: i18n.t('locale.da'), flag: 'dk' }, { id: 'nl', text: i18n.t('locale.nl'), flag: 'nl' }, { id: 'en', text: i18n.t('locale.en'), flag: 'us' }, { id: 'fr-ca', text: i18n.t('locale.fr-ca'), flag: 'ca' }, { id: 'fr', text: i18n.t('locale.fr'), flag: 'fr' }, { id: 'fr-ch', text: i18n.t('locale.fr-ch'), flag: 'ch' }, { id: 'it', text: i18n.t('locale.it'), flag: 'it' }, { id: 'ko', text: i18n.t('locale.ko'), flag: 'kr' }, { id: 'es-mx', text: i18n.t('locale.es'), flag: 'mx' }, { id: 'ru', text: i18n.t('locale.ru'), flag: 'ru' }];
    }).
    // { id: 'sv', text: i18n.t('locale.sv'), flag: 'se'},
    property(),
    click: function click(e) {
      e.stopPropagation();
      this.toggleProperty('extended');
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.close = function () {
        return _this.set('extended');
      };
      window.$(window).on('click', this.close);
    },
    willDestroyElement: function willDestroyElement() {
      window.$(window).off('click', this.close);
    },

    actions: {
      change: function change(loc) {
        this.get('i18n').set('locale', loc.id);
      }
    }
  });
});