define('test-client/locales/nl/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, _ember, _testClientLocalesEnTranslations) {
  exports['default'] = _ember['default'].merge(_ember['default'].copy(_testClientLocalesEnTranslations['default']), {
    'button.start': 'Ik ben klaar om te beginnen',
    'button.startTest': 'Start de puzzels',
    'button.instructions': 'Start instructies',
    'button.understand': 'Ik snap het',
    'button.continue': 'Verder',
    'button.finished': 'Klaar',

    'alert.fullscreen': 'Uw scherm zal automatisch naar volledige scherm weergave schakelen om afleiding te minimaliseren.',
    'alert.requiredFields': 'Zowel geslacht als leeftijd zijn verplichte velden.',
    'alert.invalidAge': 'Leeftijd (in jaren) moeten een getal groter dan 3 zijn.',
    'alert.selectEffort': 'Sleep de balk om aan te geven hoe erg u uw best heeft gedaan.',

    'title.welcome': 'Welkom',
    'title.demographics': 'Eerst enkele vragen over uzelf.',
    'title.instructions': 'Voorbeeld 1',
    'title.reminders': 'Laten we beginnen!',
    'title.feedback': 'Hoe erg heeft u uw best gedaan?',
    'title.lifestyle': 'Goed gedaan met de puzzels!',
    'title.complete': 'Bedankt voor uw deelname!',

    'form.field.required': 'verplicht',

    'form.label.gender': 'Geslacht',
    'form.label.age': 'Leeftijd',
    'form.label.race': 'Etnische achtergrond',
    'form.label.education': 'Opleiding',
    'form.label.didNotTry': 'Ik heb het niet geprobeerd',
    'form.label.didMyBest': 'Ik heb mijn best gedaan',
    'form.label.set': 'Set',
    'form.label.score': 'Score',
    'form.label.lifestyle.motivation': 'In het leven beschouw ik mijzelf:',
    'form.label.lifestyle.school': 'In het algemeen vind ik school leuk:',
    'form.label.lifestyle.rest': 'Ik voel me uitgerust na het slapen:',
    'form.label.lifestyle.restMinor': 'Gedurende de dag ben ik moe:',
    'form.label.lifestyle.fitness': 'Ik ben lichamelijk:',
    'form.label.lifestyle.handedness': 'Ik gebruik meestal mijn:',
    'form.label.lifestyle.games': 'Ik speel computerspelletjes:',
    'form.label.lifestyle.smoking': 'Ik ben een:',
    'form.label.lifestyle.alcohol': 'Ik drink alcohol:',
    'form.label.lifestyle.caffeine': 'Ik drink koffie / thee:',
    'form.label.lifestyle.control': 'In het leven voel ik dat ik:',

    'form.choice.info.genderA': 'Man',
    'form.choice.info.genderB': 'Vrouw',
    'form.choice.info.race0': 'Amerikaans-Indiaans',
    'form.choice.info.race1': 'Aziatisch',
    'form.choice.info.race2': 'Gemengde etniciteit',
    'form.choice.info.race3': 'Zwart',
    'form.choice.info.race4': 'Blank',
    'form.choice.info.race5': 'Anders',
    'form.choice.info.education0': 'Peuterschool',
    'form.choice.info.education1': 'Kleuterschool',
    'form.choice.info.education2': 'Groep 3',
    'form.choice.info.education3': 'Groep 4',
    'form.choice.info.education4': 'Groep 5',
    'form.choice.info.education5': 'Groep 6',
    'form.choice.info.education6': 'Groep 7',
    'form.choice.info.education7': 'Groep 8',
    'form.choice.info.education8': 'Middelbare school jaar 1',
    'form.choice.info.education9': 'Middelbare school jaar 2',
    'form.choice.info.education10': 'Middelbare school jaar 3',
    'form.choice.info.education11': 'Middelbare school jaar 4',
    'form.choice.info.education12': 'Middelbare school jaar 5',
    'form.choice.info.education13': 'Middelbare school jaar 6',
    'form.choice.info.education14': 'MBO',
    'form.choice.info.education15': 'HBO',
    'form.choice.info.education16': 'Post MBO',
    'form.choice.info.education17': 'Bachelor ',
    'form.choice.info.education18': 'Master ',
    'form.choice.info.education19': 'Professionele titel (o.a. dokter, advocaat, tandarts)',
    'form.choice.info.education20': 'Doctorstitel (PhD)',
    'form.choice.lifestyle.motivationA': 'Niet erg gemotiveerd',
    'form.choice.lifestyle.motivationB': 'Een beetje gemotiveerd',
    'form.choice.lifestyle.motivationC': 'Zeer gemotiveerd',
    'form.choice.lifestyle.schoolA': 'Helemaal niet',
    'form.choice.lifestyle.schoolB': 'Een beetje',
    'form.choice.lifestyle.schoolC': 'Heel erg',
    'form.choice.lifestyle.restA': 'Nooit',
    'form.choice.lifestyle.restB': 'Soms',
    'form.choice.lifestyle.restC': 'Altijd',
    'form.choice.lifestyle.smokingA': 'Niet-roker',
    'form.choice.lifestyle.smokingB': 'Voormalig roker',
    'form.choice.lifestyle.smokingC': 'Roker',
    'form.choice.lifestyle.alcoholA': 'Nooit',
    'form.choice.lifestyle.alcoholB': '1-4 glazen per week',
    'form.choice.lifestyle.alcoholC': '5 of meer glazen per week',
    'form.choice.lifestyle.caffeineA': 'Nooit',
    'form.choice.lifestyle.caffeineB': 'Eenmaal per dag',
    'form.choice.lifestyle.caffeineC': 'Meer dan eenmaal per dag',
    'form.choice.lifestyle.controlA': 'Heel weinig controle',
    'form.choice.lifestyle.controlB': 'Een beetje controle',
    'form.choice.lifestyle.controlC': 'Heel veel controle',
    'form.choice.lifestyle.fitnessA': 'Passief (weinig dagelijkse activiteit)',
    'form.choice.lifestyle.fitnessB': 'Actief (gemiddelde hoeveelheid dagelijks wandelen)',
    'form.choice.lifestyle.fitnessC': 'Sportief (regelmatig sporten)',
    'form.choice.lifestyle.handednessA': 'Rechterhand',
    'form.choice.lifestyle.handednessB': 'Linkerhand',
    'form.choice.lifestyle.handednessC': 'Beide handen',
    'form.choice.lifestyle.gamesA': 'Nooit',
    'form.choice.lifestyle.gamesB': 'Soms',
    'form.choice.lifestyle.gamesC': 'Meestal',

    'copy.reminders.nextPage': 'Op de volgende pagina begint u met de puzzels.',
    'copy.reminders.doYourBest': 'Probeer uw best te doen.',
    'copy.reminders.refresh': 'Vernieuw de pagina niet.',
    'copy.reminders.distractions': 'Schakel uw mobiel en andere afleidende apparatuur uit voordat u begint.',
    'copy.lifestyle.instructions': 'Neem alstublieft een moment om af te ronden.',
    'copy.complete.noURL': '',
    'copy.complete.URL': 'U wordt teruggestuurd naar',
    'copy.complete.URLTime': ' in 10 seconden.'
  });
});