define('test-client/pods/site/signin/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    resize: _ember['default'].inject.service(),
    height: (function () {
      return this.get('resize.height') - 210;
    }).property('resize.height'),
    actions: {
      showResetForm: function showResetForm() {
        this.toggleProperty('showResetForm');
      }
    }
  });
});