define('test-client/pods/components/pagination-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hasMore: (function () {
      return this.get('model.length') !== this.get('model.meta.pagination.count');
    }).property('model.length'),
    actions: {
      loadMore: function loadMore() {
        this.sendAction('action', this.get('model.meta.pagination.page') + 1);
      }
    }
  });
});