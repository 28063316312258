define('test-client/pods/site/invitation/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    beforeModel: function beforeModel() {
      this.set('storage.token');
      this.set('storage.userID');
    },
    model: function model(params) {
      return params.invitation;
    },
    actions: {
      submit: function submit(invitation, password, password2) {
        var _this = this;

        if (!password || password !== password2) {
          return this.controller.set('error', true);
        }

        this.get('ajax').post('/set-password', {
          data: JSON.stringify({
            data: {
              type: 'auth',
              attributes: {
                invitation: invitation,
                password: password
              }
            }
          })
        }).then(function () {
          _this.controller.set('error');
          _this.transitionTo('site.signin');
        })['catch'](function () {
          _this.controller.set('error', true);
        });
      }
    }
  });
});