define('test-client/pods/site/account/project/participants/detail/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    d3Loaded: false,
    beforeModel: function beforeModel() {
      // we load this here to avoid IE8 having to deal with it
      if (!this.d3Loaded) {
        return _ember['default'].RSVP.all([_ember['default'].$.getScript('/assets/d3.min.js'), _ember['default'].$.getScript('/assets/c3.min.js')]);
      }
    },
    model: function model(params) {
      this.d3Loaded = true;
      return new _ember['default'].RSVP.hash({
        participant: this.store.findRecord('participant', params.participant_id, {
          reload: true,
          adapterOptions: {
            query: {
              experimental: ''
            }
          }
        }),
        user: this.storage.get('user')
      });
    },
    actions: {
      resetParticipant: function resetParticipant() {
        var _this = this;

        if (confirm('Are you sure?')) {
          var participant = this.get('controller.model.participant');

          this.get('ajax').request('/participants/' + participant.id + '/reset', { type: 'PUT' }).then(function (reset) {
            _this.store.push(reset);
            _this.refresh();
          });
        }
      }
    }
  });
});