define('test-client/adapters/application', ['exports', 'ember', 'ember-data', 'test-client/config/environment'], function (exports, _ember, _emberData, _testClientConfigEnvironment) {
  //import AjaxServiceSupport from 'ember-ajax/mixins/ajax-support';

  //export default DS.JSONAPIAdapter.extend(AjaxServiceSupport, {

  // once bug https://github.com/ember-cli/ember-ajax/issues/185 is fixed we can remove this section
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend({
    storage: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    headers: _ember['default'].computed('storage.token', 'session.hash', 'session.scheme', {
      get: function get() {
        var headers = {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json'
        };

        if (this.get('session.scheme') === 'token' && this.get('storage.token')) {
          headers['Authorization'] = 'Token ' + this.get('storage.token');
        } else if (this.get('session.scheme') !== 'token') {
          headers['X-Participant'] = this.get('session.hash');
        }

        return headers;
      }
    }),
    namespace: _testClientConfigEnvironment['default'].namespace,
    host: _testClientConfigEnvironment['default'].host,

    coalesceFindRequests: true,
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      // this is kind of terrible but Mirage can't handle PATCH so we use PUT but only during integration tests
      if (!_ember['default'].testing) {
        return this._super.apply(this, arguments);
      }

      var data = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      return this.ajax(url, 'PUT', { data: data });
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var query = _ember['default'].get(snapshot, 'adapterOptions.query');
      if (query) {
        url += '?' + _ember['default'].$.param(query); // assumes no query params are present already
      }
      return url;
    }
  });
});