define('test-client/pods/components/api-key/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    actions: {
      deleteKey: function deleteKey(key) {
        if (window.confirm('This action cannot be undone. Are you sure you wish to delete this API key?')) {
          key.deleteRecord();
          key.save();
        }
      }
    }
  });
});