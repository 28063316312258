define('test-client/pods/components/puzzle-manager/component', ['exports', 'ember', 'test-client/mixins/logic', 'test-client/utils/constants'], function (exports, _ember, _testClientMixinsLogic, _testClientUtilsConstants) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var now = function now() {
    return Math.round(new Date().getTime() / 1000);
  };

  var nowMs = function nowMs() {
    return new Date().getTime();
  };

  // thanks to https://jsfiddle.net/briguy37/2MVFd/
  function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
  }

  exports['default'] = _ember['default'].Component.extend(_testClientMixinsLogic['default'], {
    error: _ember['default'].inject.service(),
    classNames: ['pattern'],
    pointerEvents: _ember['default'].A(),
    keyEvents: _ember['default'].A(),

    initialize: (function () {
      this.setProperties({
        answers: [],
        sequence: 0,
        fastClickThroughStrikes: 0,
        startTime: now(),
        setStartTime: now(),
        puzzleStartTime: nowMs()
      });

      if (!_ember['default'].testing) {
        // load the entry item's choices in a failproof manner
        this.ensureChoicesAreLoaded(this.get('item'));
      }

      this.collectPointerEvents();
      this.preventKeyEvents();
      this.preventContextClick();
    }).on('init'),

    closeSet: function closeSet() {
      window.$('body').off('keydown');
      this.set('showFeedbackForm', true);
      this.set('setEndTime', now());
    },

    administerNextItem: function administerNextItem() {
      var _this = this;

      if (this.nextID) {
        this.store.findRecord('item', this.nextID).then(function (item) {
          _this.setProperties({
            item: item,
            puzzleStartTime: nowMs(),
            pointerEvents: _ember['default'].A(),
            hoveringOver: undefined,
            keyEvents: _ember['default'].A(),
            showNetworkMessage: false
          });
          // flush the cached choices so that we make a request that will include `next`
          _this.store.peekAll('choice').forEach(function (choice) {
            // don't unload current choices or the last choice which may still need to be saved
            if (choice.get('item.id') !== item.id && _this.get('answers').indexOf(choice.id) === -1) {
              _this.store.unloadRecord(choice);
            }
          });

          _this.ensureChoicesAreLoaded(item);
        }).then(function () {
          _ember['default'].run.cancel(_this.loadingTimer);
          _ember['default'].run.later(function () {
            // we use a delay of 500ms to keep a slow double click from selecting a new choice
            // However this doesn't get called unless the needed choices are already loaded
            // because the item needed by findRecord above is sideloaded along with the choices
            _this.inFlight = false;
          }, 500);
          delete _this.nextID;
        });
      } else {
        this.sendActionOnce('lifestyle');
      }
    },
    ensureChoicesAreLoaded: function ensureChoicesAreLoaded(item) {
      var _this2 = this;

      if (this.get('isDestroying') || this.get('isDestroyed')) {
        return;
      }
      // by now the choices should be in memory but if not this will make another request for them
      item.get('choices')['catch'](function () {
        item.get('choices.content.canonicalState').forEach(function (internalModel) {
          internalModel._loadingPromise = null;
          internalModel.transitionTo('empty');
        });
        item.get('choices').reload().then(function (choices) {
          if (_this2.pendingAnswer) {
            var _pendingAnswer = _slicedToArray(_this2.pendingAnswer, 3);

            var id = _pendingAnswer[0];
            var _item = _pendingAnswer[1];
            var choiceID = _pendingAnswer[2];

            _this2.recordResponse(id, _item, choiceID);
          }
          return choices;
        })['catch'](function (error) {
          _this2.abortIfFatalError(error);

          _ember['default'].run.later(function () {
            _this2.ensureChoicesAreLoaded(item);
          }, 500);
        });
      });
    },
    findChoice: function findChoice(choiceID) {
      return this.store.findRecord('choice', choiceID);
    },
    buildResponse: function buildResponse(responseID, choice) {
      // the only way nextID should be truthy is if a pilot item was set in a response
      if (!this.nextID) {
        this.nextID = choice.get('next.id');
      }

      var participant = this.get('participant'),

      // the sequence of answers; to keep queued responses from arriving out of order
      sequence = this.incrementProperty('sequence'),

      // time-to-answer in milliseconds
      time = nowMs() - this.get('puzzleStartTime'),
          pointerEvents = JSON.stringify(this.get('pointerEvents')),
          keyEvents = JSON.stringify(this.get('keyEvents'));

      // now carry on with saving in the background
      var response = this.store.createRecord('response', {
        id: responseID, item: choice.get('item'), answer: choice, sequence: sequence, participant: participant, time: time, pointerEvents: pointerEvents, keyEvents: keyEvents
      });

      this.get('answers').push(choice.id);
      return response;
    },
    saveResponse: function saveResponse(response) {
      var _this3 = this;

      return response.save().then(function (response) {
        _this3.get('participant.responses').pushObject(response);
        return response;
      })['catch'](function (error) {
        _this3.abortIfFatalError(error);

        _this3.set('showNetworkMessage', true);
        var id = response.id;
        var item = response.get('item');
        var choiceID = response.get('answer.id');
        _ember['default'].run.later(function () {
          _this3.recordResponse(id, item, choiceID);
        }, 500);
        response.rollbackAttributes();
        throw error;
      });
    },
    chooseNextAction: function chooseNextAction(response) {
      // if this is the last item in a set then get their effort level feedback
      if (this.get('participant.responses.length') % this.get('participant.project.itemsInSet') === 0) {
        this.closeSet();
      } else {
        this.administerNextItem();
      }
      return response;
    },
    analyzeResponse: function analyzeResponse(response) {
      if (response.get('stop')) {
        this.sendActionOnce('lifestyle');
      } else if (response.get('pilot')) {
        // replace queued items so the user isn't presented with another test item
        this.nextID = response.get('pilot');
      }
      // we only [attempt to] give them a strike for making a rash choice if they were wrong
      else if (!_ember['default'].testing && !response.get('correct') && this.abortForFastClickThrough()) {
          this.sendActionOnce('complete', _testClientUtilsConstants.ABORT_FAST_FLAG_MAX_EXCEEDED);
        }
      return response;
    },
    abortIfFatalError: function abortIfFatalError(error) {
      var first = error.errors && error.errors.pop();

      if (first && first.status === '403') {
        this.sendActionOnce('complete');
        throw 'Permission denied when saving response. ' + first.detail;
      }

      if (first && first.status === '500') {
        this.sendActionOnce('complete');
        throw 'Server error';
      }
    },
    handleError: function handleError(error) {
      if (error.message !== 'Adapter operation failed') {
        throw error;
      }
    },
    recordResponse: function recordResponse(id, item, choiceID) {
      var _this4 = this;

      return _ember['default'].RSVP.Promise.resolve().then(function () {
        return _this4.findChoice(choiceID);
      }).then(function (choice) {
        return _this4.buildResponse(id, choice);
      }).then(function (response) {
        return _this4.saveResponse(response);
      }).then(function (response) {
        return _this4.chooseNextAction(response);
      }).then(function (response) {
        return _this4.analyzeResponse(response);
      })['catch'](function (error) {
        return _this4.handleError(error, choiceID);
      });
    },
    actions: {
      mouseIn: function mouseIn(choiceID) {
        this.set('hoveringOver', choiceID);
      },
      mouseOut: function mouseOut() {
        this.set('hoveringOver');
      },
      feedbackSent: function feedbackSent() {
        this.set('showFeedbackForm');

        if (this.isOverallTimeLimitExceeded()) {
          return this.sendActionOnce('lifestyle', _testClientUtilsConstants.STOP_MAX_TIME);
        }

        this.administerNextItem();
        this.preventKeyEvents();
        // next item is already in memory, no network request
        this.set('setStartTime', now());
      },
      submitAnswer: function submitAnswer(item, choiceID) {
        var _this5 = this;

        // a click was already caught
        if (this.inFlight) {
          return;
        }
        this.inFlight = true;
        var id = generateUUID();
        this.pendingAnswer = [id, item, choiceID];

        // set the "loading" notice to show in 100ms if we don't have background data by then
        this.loadingTimer = _ember['default'].run.later(function () {
          return !_ember['default'].testing && _this5.set('showNetworkMessage', true);
        }, 1500);

        this.recordResponse(id, item, choiceID);
      }
    },
    willDestroy: function willDestroy() {
      window.$('body').off('mousemove keydown contextmenu');
      _ember['default'].run.cancel(this.loadingTimer);
      this._super.apply(this, arguments);
    },
    // without this we can end up with a 'complete' action being sent after this route is left
    sendActionOnce: function sendActionOnce(action, value) {
      if (!this.get('isDestroyed') && !this.get('isDestroying')) {
        this.sendAction(action, value);
      }
    }
  });
});