define('test-client/locales/ko/translations', ['exports', 'ember', 'test-client/locales/en/translations'], function (exports, _ember, _testClientLocalesEnTranslations) {
  exports['default'] = _ember['default'].merge(_ember['default'].copy(_testClientLocalesEnTranslations['default']), {
    'locale.en': '영어',
    'locale.fr': '프랑스어',

    'button.start': '시작할 준비가 되었으면, 이 버튼을 눌러주세요',
    'button.startTest': '퀴즈를 시작합니다',
    'button.instructions': '이 버튼을 누르면, 퀴즈에 대한 설명을 시작합니다',
    'button.understand': '정답입니다',
    'button.continue': '계속하세요',
    'button.finished': '끝맞쳤습니다',
    'alert.fullscreen': '주의분산을 최소화하기 위해 전체화면 모드로 전환합니다',
    'alert.requiredFields': '성별과 나이를 입력하시기 바랍니다',
    'alert.invalidAge': '나이는 3살 이상이어야 가능합니다',
    'alert.selectEffort': '바를 이용해서 퀴즈가 얼마나 어려웠는지 평가하여 주십시오',
    'title.welcome': '저희 퍼즐게임에 참여해주셔서 감사합니다',
    'title.demographics': '퀴즈 참여자 분에 대한 정보부터 시작하겠습니다',
    'title.instructions': '예 {{index}}',
    'title.reminders': '시작합니다',
    'title.feedback': '문제를 풀기위해 얼마나 열심히 시도하셨는지요',
    'title.lifestyle': '정말 잘하셨습니다',
    'title.complete': '퀴즈에 참여하여 주셔서 감사합니다',

    'form.field.required': '필수',

    'form.label.gender': '성별',
    'form.label.age': '나이',
    'form.label.race': '인종',
    'form.label.education': '교육수준',
    'form.label.didNotTry': '열심히 하지 않았습니다',
    'form.label.didMyBest': '최선을 다했습니다',
    'form.label.set': '세트',
    'form.label.score': '점수',
    'form.label.lifestyle.motivation': '내인생에 나는',
    'form.label.lifestyle.school': '일반적으로 나는 학교를 좋아합니다',
    'form.label.lifestyle.rest': '숙면을 합니다',
    'form.label.lifestyle.restMinor': '피곤함을 느낍니다',
    'form.label.lifestyle.fitness': '육체적으로',
    'form.label.lifestyle.handedness': '대체로 나는',
    'form.label.lifestyle.games': '컴퓨터 게임을 합니다',
    'form.label.lifestyle.smoking': '나는',
    'form.label.lifestyle.alcohol': '음주를 합니다',
    'form.label.lifestyle.caffeine': '커피나 차를 마십니다',
    'form.label.lifestyle.control': '내 인생에 내가',

    'form.choice.info.genderA': '남자',
    'form.choice.info.genderB': '여자',
    'form.choice.info.race0': '미국 인디언',
    'form.choice.info.race1': '동양인',
    'form.choice.info.race2': '혼혈',
    'form.choice.info.race3': '흑인',
    'form.choice.info.race4': '백인',
    'form.choice.info.race5': '기타',
    'form.choice.info.education0': '유아원',
    'form.choice.info.education1': '유치원',
    'form.choice.info.education2': '초등학교 1학년',
    'form.choice.info.education3': '초등학교 2학년',
    'form.choice.info.education4': '초등학교 3학년',
    'form.choice.info.education5': '초등학교 4학년',
    'form.choice.info.education6': '초등학교 5학년',
    'form.choice.info.education7': '초등학교 6학년',
    'form.choice.info.education8': '중학교 1학년',
    'form.choice.info.education9': '중학교 2학년',
    'form.choice.info.education10': '중학교 3학년',
    'form.choice.info.education11': '고등학교 1학년',
    'form.choice.info.education12': '고등학교 2학년',
    'form.choice.info.education13': '고등학교 3학년',
    'form.choice.info.education14': '직업학교',
    'form.choice.info.education15': '대학 중퇴',
    'form.choice.info.education16': '전문 대학',
    'form.choice.info.education17': '4년대 졸업',
    'form.choice.info.education18': '석사',
    'form.choice.info.education19': '전문대학원 (예:법률전문대학원, 의료전문대학원)',
    'form.choice.info.education20': '박사',
    'form.choice.lifestyle.motivationA': '동기부여가 되지 않습니다',
    'form.choice.lifestyle.motivationB': '어느 정도 동기부여가 있습니다',
    'form.choice.lifestyle.motivationC': '매우 동기부여가 있습니다',
    'form.choice.lifestyle.schoolA': '전혀 아님',
    'form.choice.lifestyle.schoolB': '조금',
    'form.choice.lifestyle.schoolC': '매우 많이',
    'form.choice.lifestyle.restA': '전혀',
    'form.choice.lifestyle.restB': '자주',
    'form.choice.lifestyle.restC': '항상',
    'form.choice.lifestyle.smokingA': '비흡연자',
    'form.choice.lifestyle.smokingB': '기존 흡연',
    'form.choice.lifestyle.smokingC': '흡연자',
    'form.choice.lifestyle.alcoholA': '전혀하지 않음',
    'form.choice.lifestyle.alcoholB': '1주에 1-4번정도',
    'form.choice.lifestyle.alcoholC': '1주에 5번 이상',
    'form.choice.lifestyle.caffeineA': '전혀하지 않음',
    'form.choice.lifestyle.caffeineB': '하루 한잔',
    'form.choice.lifestyle.caffeineC': '하루 두잔 이상',
    'form.choice.lifestyle.controlA': '통제가 되지 않음',
    'form.choice.lifestyle.controlB': '어느정도 통제가 됨',
    'form.choice.lifestyle.controlC': '완벽할만큼 통제가 됨',
    'form.choice.lifestyle.fitnessA': '정적 (주로 의자에 앉아 시간을 보냄)',
    'form.choice.lifestyle.fitnessB': '활발 (어느정도 걷기가 생활화되어 있음)',
    'form.choice.lifestyle.fitnessC': '운동을 생활화함',
    'form.choice.lifestyle.handednessA': '왼손잡이',
    'form.choice.lifestyle.handednessB': '오른손잡이',
    'form.choice.lifestyle.handednessC': '양손잡이',
    'form.choice.lifestyle.gamesA': '전혀',
    'form.choice.lifestyle.gamesB': '가끔',
    'form.choice.lifestyle.gamesC': '거의 항상',

    'copy.reminders.nextPage': '다음 페에지에서 퀴즈를 시작할것입니다',
    'copy.reminders.doYourBest': '최선을 다해 주십시오',
    'copy.reminders.refresh': '화면을 클릭하지 마십시오',
    'copy.reminders.distractions': '전화기나 기타 방해가 되는 것들을 중지하여주십시오',
    'copy.lifestyle.instructions': '마지막까지 최선을 다해주십시오',
    'copy.complete.noURL': '끝내셨습니다. 감사합니다. 좋은 하루 되십시오',
    'copy.complete.URL': '페이지가 다음으로',
    'copy.complete.URLTime': '10초 이내에'
  });
});