define("test-client/utils/constants", ["exports"], function (exports) {
  var ABORT_FAST_FLAG_MAX_EXCEEDED = 0;
  exports.ABORT_FAST_FLAG_MAX_EXCEEDED = ABORT_FAST_FLAG_MAX_EXCEEDED;
  var ABORT_KEY_PRESS_END = 2;
  exports.ABORT_KEY_PRESS_END = ABORT_KEY_PRESS_END;
  var ABORT_DEV_TOOLS_OPENED = 3;
  exports.ABORT_DEV_TOOLS_OPENED = ABORT_DEV_TOOLS_OPENED;
  var STOP_MAX_TIME = 10;
  exports.STOP_MAX_TIME = STOP_MAX_TIME;
  var STOP_BROWSER_CLOSED = 11;
  exports.STOP_BROWSER_CLOSED = STOP_BROWSER_CLOSED;
});