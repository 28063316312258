define('test-client/pods/components/size-overlay/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    alertSent: false,
    resize: _ember['default'].inject.service(),
    classNameBindings: ['isSmall:expandedOverlay'],
    isSmall: (function () {
      var width = this.get('resize.width'),
          height = this.get('resize.height'),
          isSmall = width < 855 || height < 625;

      if (isSmall && !this.alertSent) {
        Raven.captureMessage('Small display was used. Size ' + width + 'x' + height, {
          level: 'info'
        });
        this.alertSent = true;
      }

      return isSmall;
    }).property('resize.width', 'resize.height')
  });
});